import joi from "joi";

const coordinateSchema = joi
  .array()
  .items(joi.number().required())
  .min(2)
  .max(3);

// validation on large regions was taking a long time due to checking each coordinate on large regions
const coordinateSchemaAny = joi.any();

const poiPropSchema = joi.object({
  icon: joi.string().required(),
  iconColor: joi.string(),
  pinColor: joi.string(),
  pinBorderColor: joi.string().allow("").default("#000000").optional(),
  text: joi.string().required(),
  renderLocationQr: joi.boolean(),
  id: joi.string().required(),
});

const pathPropSchema = joi.object({
  id: joi.string().uuid().required(),
  color: joi.string().optional().allow(""),
  direction: joi.string().optional(),
});

const trailPropSchema = joi.object({
  name: joi.string().required(),
  description: joi.string(),
  trailType: joi.string().required().valid("loop", "oab", "p2p"),
  completionTime: joi.string().required(),
  trailLength: joi.string().required(),
  trailDifficulty: [joi.string().required(), joi.number().required()],
  id: joi.string().uuid().required(),
});

const trailGeometrySchema = (validateCoordinates: boolean) =>
  joi.object({
    type: joi.string().valid("Point", "LineString", "MultiLineString"),
    properties: joi.alternatives().conditional("type", {
      switch: [
        { is: "Point", then: poiPropSchema },
        { is: "LineString", then: pathPropSchema },
        { is: "MultiLineString", then: pathPropSchema },
      ],
    }),
    coordinates: joi.alternatives().conditional("type", {
      switch: [
        {
          is: "Point",
          then: validateCoordinates ? coordinateSchema : coordinateSchemaAny,
        },
        {
          is: "LineString",
          then: joi
            .array()
            .items(
              validateCoordinates ? coordinateSchema : coordinateSchemaAny
            ),
        },
        {
          is: "MultiLineString",
          then: joi
            .array()
            .items(
              joi
                .array()
                .items(
                  validateCoordinates ? coordinateSchema : coordinateSchemaAny
                )
            ),
        },
      ],
    }),
  });

const trailSchema = (validateCoordinates: boolean) =>
  joi.object({
    type: joi.string().equal("Feature").required(),
    properties: trailPropSchema,
    geometry: joi.object({
      type: joi.string().required().equal("GeometryCollection"),
      geometries: joi.array().items(trailGeometrySchema(validateCoordinates)),
    }),
  });

const regionPropertiesSchema = joi.object({
  clusterZoomBreakpoint: joi.number().required().min(1).max(20),
  minZoom: joi.number().required().min(1).max(20),
  maxZoom: joi.number().required().min(1).max(20),
  partnerConfigId: joi.string().uuid().required(),
  tileCachingStrategy: joi
    .string()
    .required()
    .valid("ALL_WITHIN_BOUNDS", "AROUND_POINTS"),
  navModeZoom: joi.number().min(1).max(20),
  navModeTilt: joi.number().min(0).max(67.5),
});

const regionSchema = joi.object({
  type: joi.string().equal("FeatureCollection").required(),
  name: joi.string(),
  features: joi.array().items(trailSchema(false)),
  properties: regionPropertiesSchema,
  crs: joi.object({
    type: joi.string().equal("name").required(),
    properties: joi.object({
      name: joi.string().equal("urn:ogc:def:crs:OGC:1.3:CRS84").required(),
    }),
  }),
});

export const Validators = {
  regionSchema,
  regionPropertiesSchema,
  trailSchema,
  trailPropSchema,
  poiPropSchema,
  pathPropSchema,
  coordinateSchema,
};
