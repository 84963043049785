import { Button, Card } from "@mui/material";
import React, { useCallback } from "react";
import { Drt } from "../../lib/Drt";
import PathEditor from "./PathEditor";
import PoiEditor from "./PoiEditor";

type GeometryEditorProps = {
  geometry?: Drt.TrailGeometry;
  defaultPois: Drt.PointOfInterestProperties[];
  selectedTrailId?: string;
  onEditGeometryProperties: <T extends Drt.TrailGeometry>(
    geometryId: string,
    newProps: Partial<T["properties"]>
  ) => void;
  onDeleteTrailGeometry: (trailId: string, geometryId: string) => void;
  extractGeometryToTrail: (
    sourceTrail: string,
    sourceGeometry: string,
    targetTrailId?: string
  ) => void;
  trailList: { id: string; name: string }[];
};

const GeometryEditor = ({
  geometry,
  defaultPois,
  onEditGeometryProperties,
  onDeleteTrailGeometry,
  selectedTrailId,
  extractGeometryToTrail,
  trailList,
}: GeometryEditorProps) => {
  const onDeleteClick = useCallback(() => {
    if (selectedTrailId && geometry) {
      onDeleteTrailGeometry(selectedTrailId, geometry.properties.id);
    }
  }, [selectedTrailId, geometry, onDeleteTrailGeometry]);

  if (!geometry) {
    return <></>;
  }
  return (
    <Card
      key={geometry.properties.id}
      sx={{
        position: "absolute",
        bottom: 0,
        right: 0,
        // leaflet uses z-index 1-399
        zIndex: "400",
        mr: 1,
        mb: 3,
        p: 1,
        backgroundColor: "#ffffff",
        minWidth: 250,
        maxWidth: 350,
      }}
    >
      {geometry.type === "Point" && (
        <PoiEditor
          poi={geometry}
          defaultPois={defaultPois}
          onEditGeometryProperties={onEditGeometryProperties}
        />
      )}
      {(geometry.type === "LineString" ||
        geometry.type === "MultiLineString") && (
        <PathEditor
          path={geometry}
          trailId={selectedTrailId}
          extractGeometryToTrail={extractGeometryToTrail}
          trailList={trailList}
          onEditGeometryProperties={onEditGeometryProperties}
        />
      )}
      <Button onClick={onDeleteClick} fullWidth>
        Delete Geometry
      </Button>
    </Card>
  );
};

export default GeometryEditor;
