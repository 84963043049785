import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Drt } from "../../lib/Drt";
import _ from "lodash";

type PathEditorProps = {
  path: Drt.TrailPath;
  trailId?: string;
  extractGeometryToTrail: (
    sourceTrail: string,
    sourceGeometry: string,
    targetTrailId?: string
  ) => void;
  trailList: { id: string; name: string }[];
  onEditGeometryProperties: (
    geometryId: string,
    newProps: Partial<Drt.PathProperties>
  ) => void;
};

const debouncedFn = _.debounce((fn: (...args: any[]) => void, ...args) => {
  fn(...args);
}, 50);

const PathEditor = ({
  path,
  extractGeometryToTrail,
  trailId,
  trailList,
  onEditGeometryProperties,
}: PathEditorProps) => {
  const [selectedTrailId, setSelectedTrailId] = useState<string>("new");
  const onExtractClick = useCallback(
    () =>
      trailId &&
      extractGeometryToTrail(
        trailId,
        path.properties.id,
        selectedTrailId === "new" ? undefined : selectedTrailId
      ),
    [path, trailId, selectedTrailId]
  );
  const options = useMemo(
    () => [{ id: "new", name: "New Trail" }, ...trailList],
    [trailList]
  );
  const onTrailChange = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedTrailId(event.target.value);
    },
    [setSelectedTrailId]
  );
  // const onColorChange = useCallback(
  //   _.debounce(
  //     (e: ChangeEvent<HTMLInputElement>) => {
  //       console.log("updating color to ", e.target.value);
  //       onEditGeometryProperties(path.properties.id, {
  //         color: e.target.value,
  //       });
  //     },
  //     200,
  //     { trailing: true }
  //   ),
  //   [onEditGeometryProperties]
  // );
  return (
    <Stack spacing={1}>
      {/* <TextField
        label="color"
        type="color"
        fullWidth
        value={path.properties.color || "#DB6231"}
        onChange={(e) =>
          debouncedFn(onEditGeometryProperties, path.properties.id, {
            color: e.target.value,
          })
        }
      /> */}
      <Select
        label="Destination Trail"
        size="small"
        value={selectedTrailId}
        onChange={onTrailChange}
        fullWidth
      >
        {options.map((opt) => (
          <MenuItem value={opt.id} key={opt.id}>
            {opt.name}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={onExtractClick}>Extract to new Trail</Button>
    </Stack>
  );
};

export default PathEditor;
